import { graphql, useStaticQuery } from "gatsby";

export interface Consultant {
  id: string;
  name: string;
  title: string;
  avatar: string;
}

export const useConsultants = (): Consultant[] => {
  const data = useStaticQuery(graphql`
    query Consultants {
      contentfulTeam(name: { eq: "Consultants" }) {
        people {
          id
          name
          title
          avatar {
            file {
              url
            }
          }
        }
      }
    }
  `);

  return data.contentfulTeam.people.map(
    (node): Consultant => ({
      id: node.id,
      name: node.name,
      title: node.title,
      avatar: node.avatar.file.url,
    })
  );
};
